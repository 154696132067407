import { CookieConsentConfig, GuiOptions, Translation } from 'vanilla-cookieconsent';

import { CookieCategory, CookieConsentEventName, CookieService, TrackerID } from './types';

export class CookieConsentConfigBuilder {
    private readonly configuration: CookieConsentConfig;

    private constructor() {
        this.configuration = {
            root: 'app-root',
            autoClearCookies: true,
            autoShow: false,
            disablePageInteraction: false,
            hideFromBots: true,
            manageScriptTags: false,
            mode: 'opt-in',
            revision: 0,
            guiOptions: {
                consentModal: {
                    layout: 'box wide',
                    position: 'bottom center',
                    equalWeightButtons: true,
                    flipButtons: false,
                },
                preferencesModal: {
                    layout: 'box',
                    equalWeightButtons: true,
                    flipButtons: false,
                },
            },
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true,
                },
                analytics: {
                    enabled: false,
                    readOnly: false,
                    services: {},
                },
            },
            language: {
                default: 'en',
                autoDetect: 'browser',
                translations: {},
            },
        };
    }

    public static create() {
        return new CookieConsentConfigBuilder();
    }

    public build() {
        return {
            ...this.configuration,
        };
    }

    public addAutoShow(val = false) {
        this.configuration.autoShow = val;

        return this;
    }

    public addAnalytics({ id, ...service }: CookieService & { id: TrackerID }) {
        const categoryType: CookieCategory = 'analytics';

        this.configuration.categories[categoryType].services[id] = {
            ...service,
        };

        return this;
    }

    public addCallback<T extends CookieConsentEventName>(eventName: T, cb: CookieConsentConfig[T]) {
        this.configuration[eventName] = cb;

        return this;
    }

    public addTranslation(locale: string, translation: string | Translation) {
        this.configuration.language.translations[locale] = translation;

        return this;
    }

    public overrideConsentModal(config: GuiOptions['consentModal']) {
        this.configuration.guiOptions.consentModal = config;

        return this;
    }

    public overridePreferencesModal(config: GuiOptions['preferencesModal']) {
        this.configuration.guiOptions.preferencesModal = config;

        return this;
    }
}
