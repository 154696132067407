import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScriptLoaderService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    loadExternalScript(attributes: Record<string, string | boolean>): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        Object.assign(script, attributes);
        this.document.body.appendChild(script);
    }
}
